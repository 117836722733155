var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-section wbs-break"},[_c('div',{staticClass:"section-block"},[(!_vm.loading && (!_vm.filteredLeftovers || !_vm.filteredLeftovers.length))?_c('div',{staticClass:"mb-4 row"},[_c('div',{staticClass:"col-lg-12 text-center"},[_c('p',{staticClass:"mt-5"},[_c('SVGSad',{staticClass:"imagery",attrs:{"height":"252","alt":"Грустный персонаж"}})],1),_c('h3',{staticClass:"mt-5",staticStyle:{"color":"#dadada"}},[_vm._v(" К сожалению, мы не смогли найти продуктов, подходящих под ваш запрос и фильтры ")]),_c('p',{staticStyle:{"color":"#dadada"}},[_vm._v(" Попробуйте выполнить другой запрос ")])])]):_vm._e(),(_vm.moreLeftovers && _vm.moreLeftovers.length > 0 && !_vm.search)?_c('div',{staticClass:"row"},_vm._l((_vm.moreLeftovers),function(leftover){return _c('ProductCard',{key:leftover.id + 'big',staticClass:"mb-4",attrs:{"title":leftover.product.title,"size":"big","price":leftover.price,"net-price":leftover.product.netPrice,"preview":leftover.product.img,"kcal":leftover.product.calories,"weight":leftover.product.weight,"tag":leftover.tag,"product-route":{name: _vm.RouteNames.product, params: {id: leftover.product.id}}}},[(leftover.batchesObject)?_c('BButton',{staticClass:"btn-lg w-100",attrs:{"disabled":!_vm.isShowAddToBasketButton,"variant":"primary"},on:{"click":function($event){return _vm.addToCard(leftover.product.id)}}},[(_vm.thereIsField(leftover.price)
              && leftover.price !== leftover.product.salePrice)?[_c('del',[_vm._v(" "+_vm._s(leftover.product.salePrice)+" ")]),_vm._v(" / ")]:_vm._e(),_c('span',{class:{
              'price-bold': _vm.thereIsField(leftover.price)
                && leftover.price !== leftover.product.salePrice}},[_vm._v(" "+_vm._s(_vm.thereIsField(leftover.price) ? leftover.price : leftover.product.salePrice)+" ")])],2):_vm._e()],1)}),1):_vm._e(),_c('div',{staticClass:"row"},_vm._l((_vm.filteredLeftovers),function(leftover){return _c('ProductCard',{key:leftover.id,staticClass:"mb-4",attrs:{"title":leftover.product.title,"size":"small","price":leftover.price,"net-price":leftover.product.netPrice,"preview":leftover.product.img,"kcal":leftover.product.calories,"weight":leftover.product.weight,"tag":leftover.tag,"product-route":{name: _vm.RouteNames.product, params: {id: leftover.product.id}}}},[(leftover.batchesObject)?_c('BButton',{staticClass:"btn-lg w-100",attrs:{"disabled":!_vm.isShowAddToBasketButton,"variant":"primary","title":"Добавить в корзину"},on:{"click":function($event){return _vm.addToCard(leftover.product.id)}}},[(_vm.thereIsField(leftover.price)
              && leftover.price !== leftover.product.salePrice)?[_c('del',[_vm._v(" "+_vm._s(leftover.product.salePrice)+" ")]),_vm._v(" / ")]:_vm._e(),_c('span',{class:{
              'price-bold': _vm.thereIsField(leftover.price)
                && leftover.price !== leftover.product.salePrice
            }},[_vm._v(" "+_vm._s(_vm.thereIsField(leftover.price) ? leftover.price : leftover.product.salePrice)+" ")])],2):_vm._e()],1)}),1),_c('div',{staticClass:"align-items-center d-flex h-100 justify-content-center w-100",class:{
        'invisible': !_vm.loading
      }},[_c('BSpinner',{attrs:{"variant":"primary"}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }