
































































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { BButton, BSpinner } from 'bootstrap-vue'
import leftovers from '@/store/leftovers'
import { RouteNames } from '@/router'
import { intersection } from 'lodash-es'
import { Maybe } from 'graphql/jsutils/Maybe'
import { SubcategoryFragment } from '@/graphql/default/category.graphql'
import { Scalars } from '@/graphql/default/graphql.schema'
import { cart } from '@/store/cart/cartModule'
import terminalModule from '@/store/terminal'

const ProductCard = () => import('@/components/ProductCard.vue')
const SVGSad = () => import('@/assets/images/svg/sad.svg?inline')

@Component({
  components: { ProductCard, BSpinner, BButton, SVGSad }
})

export default class Store extends Vue {
  @Prop({ type: [String] }) search?: string
  @Prop({ type: [Array, String] })
  subcategory?: SubcategoryFragment['id'][] | SubcategoryFragment['id']

  readonly RouteNames = RouteNames
  private windowWidth = 0;

  get isShowAddToBasketButton() {
    return !terminalModule.getters.terminal?.isDemoMode
  }

  get leftovers() {
    return leftovers.mapLeftovers
  }

  // вся фильтрация должна быть клиентской, для быстродействия и кеширования
  get filteredLeftovers(): typeof leftovers.mapLeftovers {
    return this.leftovers?.filter((node) => {
      const subcategory = this.subcategory && (Array.isArray(this.subcategory)
        ? this.subcategory : [this.subcategory])

      const isCorrectSubcategory = !(subcategory?.length) ||
        !!intersection(node?.product.subcategory.edges
          .map((sub) => sub?.node?.id), subcategory)
          .length

      return (node?.currentAmount && node?.currentAmount > 0) &&
      (!this.search?.toLowerCase() || node?.product.title.toLowerCase()
        .includes(this.search?.toLowerCase())) &&
        isCorrectSubcategory
    })
  }

  async addToCard(productId: Scalars['ID']): Promise<void> {
    if (!this.isShowAddToBasketButton) return
    cart.addItem({ productId, count: 1 })
  }

  thereIsField(value: Maybe<number>) {
    return value || value === 0
  }

  handleResize() {
    this.windowWidth = window.innerWidth
  }

  get moreLeftovers() {
    const moreLeftoversLenght = this.windowWidth >= 1200 ? 4 : 3
    return this.filteredLeftovers?.slice().sort((a, b) => {
      if (a && b) {
        return b.currentAmount - a.currentAmount
      }
      return 0
    }).slice(0, moreLeftoversLenght)
  }

  get loading() {
    return leftovers.loading
  }

  mounted():void {
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
  }

  destroyed():void {
    window.removeEventListener('resize', this.handleResize)
  }
}
